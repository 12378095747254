jQuery(document).ready(function($) {
  require('./components/mobile-header.js')
  require('./components/slider.js')

  jQuery('.team .popup-button').click(function() {
    jQuery(this).closest('.single-member').addClass('active');
    jQuery('body, html').css('overflow-y', 'hidden');

    jQuery('.popup-close').click(function() {
      jQuery(this).closest('.single-member').removeClass('active');
      jQuery('body, html').css('overflow-y', 'auto');
    })
  });


  jQuery('.sub-services .service-popup-button').click(function() {
    jQuery(this).closest('.single-sub-service').addClass('active');
    jQuery('body, html').css('overflow-y', 'hidden');

    jQuery('.service-popup-close').click(function() {
      jQuery(this).closest('.single-sub-service').removeClass('active');
      jQuery('body, html').css('overflow-y', 'auto');
    })
  });


  $('.map').click(function(){
    if(!$(this).hasClass("active")){
      $(".active").removeClass("active");
      $(this).addClass("active");
     }else{
      return false;//this prevents flicker
    }
  });
});